import React from "react";
import "./Rotate.css";

const Rotate = () => {
  return (
    <div className="rotate">
      <p>Merci de pivoter votre appareil</p>
    </div>
  );
};

export default Rotate;
