import React, { useRef, useEffect } from "react";
import "./Activities.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap/gsap-core";
gsap.registerPlugin(ScrollTrigger);

const Activities = () => {
  const lines = useRef(null);
  const frstLine = useRef(null);
  const scndLine = useRef(null);
  const thrdLine = useRef(null);

  useEffect(() => {
    gsap.set(lines.current, { yPercent: -50 });
    gsap.fromTo(
      frstLine.current,
      { xPercent: -10 },
      {
        xPercent: 0,
        ease: "none",
        scrollTrigger: {
          trigger: frstLine.current,
          scrub: 0.5,
          invalidateOnRefresh: true,
          start: "0% 120%",
        },
      }
    );
    gsap.fromTo(
      scndLine.current,
      { xPercent: 0 },
      {
        xPercent: -10,
        ease: "none",
        scrollTrigger: {
          trigger: scndLine.current,
          scrub: 0.5,
          invalidateOnRefresh: true,
          start: "0% 120%",
        },
      }
    );
    gsap.fromTo(
      thrdLine.current,
      { xPercent: -10 },
      {
        xPercent: 0,
        ease: "none",
        scrollTrigger: {
          trigger: thrdLine.current,
          scrub: 0.5,
          invalidateOnRefresh: true,
          start: "0% 120%",
        },
      }
    );
  }, []);

  return (
    <div className="home__activities">
      <div ref={lines} className="home__activities__lines">
        <div className="home__activities__height home__activities__centered">
          <p ref={frstLine} className="home__activities__linesTxt">
            VOLTIGE OBSTACLE EQUITHERAPIE DRESSAGE PONEYGAMES BALADES CROSS
            VOLTIGE OBSTACLE EQUITHERAPIE DRESSAGE PONEYGAMES BALADES CROSS
            VOLTIGE OBSTACLE EQUITHERAPIE DRESSAGE PONEYGAMES BALADES CROSS
          </p>
        </div>
        <div className="home__activities__height home__activities__centered">
          <p ref={scndLine} className="home__activities__linesTxt">
            EQUITHERAPIE BALADES PONEYGAMES CROSS OBSTACLE DRESSAGE VOLTIGE
            EQUITHERAPIE BALADES PONEYGAMES CROSS OBSTACLE DRESSAGE VOLTIGE
            EQUITHERAPIE BALADES PONEYGAMES CROSS OBSTACLE DRESSAGE VOLTIGE
          </p>
        </div>
        <div className="home__activities__thirdHeight home__activities__centered">
          <p
            ref={thrdLine}
            className="home__activities__thirdLine home__activities__linesTxt"
          >
            OBSTACLE DRESSAGE CROSS VOLTIGE PONEYGAMES BALADES EQUITHERAPIE
            OBSTACLE DRESSAGE CROSS VOLTIGE PONEYGAMES BALADES EQUITHERAPIE
            OBSTACLE DRESSAGE CROSS VOLTIGE PONEYGAMES BALADES EQUITHERAPIE
          </p>
        </div>
      </div>
      <svg
        className="home__activities__img"
        height="100%"
        width="100%"
        viewBox="0 0 821 165"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="821" height="165" fill="url(#pattern0)" />
        <defs>
          <pattern
            id="pattern0"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use xlinkHref="#image0" transform="scale(0.00121803 0.00606061)" />
          </pattern>
          <image
            id="image0"
            width="821"
            height="165"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAzUAAAClAQMAAABfrEFxAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAZQTFRF//XcAAAArfXLDwAAAAJ0Uk5T/wDltzBKAAADfklEQVR4nO2WTXLqMBCESWWRJUfwUXw0+2g+Ckdg6YULhUeQ1PPTdlAeOKkatl3qr6dHtjkc4vdnfkM6+cIxLb7wntLIvM4M0yVy6upGTl2Fy2Neh8PbVUqzn+3684TjP+H0kNdXBNfuPTG7WwCv0lsAMlBidl8BHLuvAJ7dLYDf9f2QYzcwu45F4155VKe4+yHHbmB21KseShM7ZNb6lphdT6PVQ6bUfMhc4I98xETLgnPjcju2hSyYBXUsGveq7ZgWyqAmdh7UxKZeeEjHLu2Y2EXQjZZBbaPlGpjl1XCqhdKOiU29sB0duw6qYtdBdewqmIsA4VTsGk41WgdVsbmXCCdjw6Aqdh1UxQYvcxE6pkE4FRsGlbFhUPMsQDgZG8PJzYEgK6BeMpyMjeFEbBw0fc9LhpOxsVARGweVsamX3LbcKoYTsXFQEZt7qRJEPSiIreKgIrbw4s+c1EQ4UU/PYgsvdbGPTBPhRD0Dq0B6YQWqBNRkOKxHCBfqhRWoElCT4aAeWWiiXliBKgE1yYF6ZKFYAfXSJWA9sgSoRxaKHOplSoB6ZAlQj+JM3/AyJUA9sgTgyEKhHsURD+MH1aQA9XRSqPWozPxdhZoKB/X0UpiZF39XoaY5tZ6BVUC9bAm1HlVCC2cETs80XUJdgxJq1TrztMIpmg5X1qALTdtetoRaj+Ys2xzqZUuoWsc4enG1as2h717UNKesQS+u1kO9nBLKGvqfc/g7HrRBK2xxpWrjxd/xoBnOuMXhXk4JRdNC4XRaODMO/5aAZjh5Db0W5k0vp4S8BlN2A4d/S9Y4eQ2DFpYtL6/svAZTdgtnbOHkdRvhsuXllZ3XYMtu4EwtnJktLm15eWXnNdiyGzinFs7CFpfXQL28srPW/Q8O/QZXzXIuWxzqtab1jGMvSF63Ffi3vmj7cRK7IPeVci+37JkttYHD/1MUbUfOSC7i/epwL3epC1tqA4f/dynajpyJXcR5w2tFc5bawOH/xbK2J+e0zqFea5qz1DtnsMKyyemezBlXOOPenPM6h3q1cRzh0s7xLmkDZ7pz+h05096ceZ1DvZo43ositXO8h+EHnOHJnNMv4Jx+OYd6PcpZXsnxXnwv44zNnO5vcc4v5niHghOc4AQnOMEJTnCCE5zgBCc4wQlOcIITnOAEJzjBCU5wghOc4AQnOM/jvOL3CfVWl6+OJHQcAAAAAElFTkSuQmCC"
          />
        </defs>
      </svg>
    </div>
  );
};

export default Activities;
