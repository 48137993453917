import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { useLocation } from "react-router-dom";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Contact.css";
import contact from "../../images/contact.webp";

gsap.registerPlugin(ScrollTrigger);

const Contact = () => {
  const location = useLocation();

  const open = (url) => {
    let win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  };

  const img = useRef(null);
  const blocks = useRef([]);

  useEffect(() => {
    if (location.pathname === "/contact") {
      gsap
        .timeline()
        .from(img.current, {
          scale: 1.03,
          duration: 3,
          ease: "none",
          delay: 1,
        })
        .from(
          blocks.current,
          {
            duration: 2.2,
            yPercent: 250,
            ease: "power4.out",
            rotation: 0.01,
            stagger: {
              amount: 0.9,
            },
          },
          "-=3"
        );
    }
  }, [location.pathname]);

  return (
    <div className="contact">
      <div className="overflowHid">
        <div
          ref={img}
          className="contact__img"
          style={{
            backgroundImage: `url(${contact})`,
            backgroundSize: "cover",
            backgroundPosition: "35% 50%",
          }}
        ></div>
      </div>
      <div className="contact__txt">
        <h1>
          <div className="overflowHid">
            <div
              ref={(element) => {
                blocks.current[0] = element;
              }}
            >
              Contactez-
            </div>
          </div>
          <div className="overflowHid">
            <div
              ref={(element) => {
                blocks.current[1] = element;
              }}
            >
              nous.
            </div>
          </div>
        </h1>
        <div className="contact__txt__links">
          <div className="overflowHid">
            <button
              ref={(element) => {
                blocks.current[2] = element;
              }}
              onClick={() =>
                open("https://www.facebook.com/Les.Ecuries.de.Gammarth")
              }
            >
              <div>
                <span>01</span>
                <h2>Facebook</h2>
              </div>
            </button>
          </div>
          <div className="overflowHid">
            <button
              ref={(element) => {
                blocks.current[3] = element;
              }}
              onClick={() =>
                open(
                  "https://instagram.com/les_ecuries_de_gammarth?igshid=a8loye5rapep"
                )
              }
            >
              <div>
                <span>02</span>
                <h2>Instagram</h2>
              </div>
            </button>
          </div>
          <div className="overflowHid">
            <button
              ref={(element) => {
                blocks.current[4] = element;
              }}
              onClick={() =>
                open(
                  "https://www.google.com/maps/place/Les+Ecuries+de+Gammarth/@36.910449,10.2980232,15z/data=!4m5!3m4!1s0x0:0xb04651acbc84fee0!8m2!3d36.910449!4d10.2980232"
                )
              }
            >
              <div>
                <span>03</span>
                <h2>Google Maps</h2>
              </div>
            </button>
          </div>
        </div>
        <div className="contact__txt__deco">
          <div className="overflowHid">
            <p
              ref={(element) => {
                blocks.current[5] = element;
              }}
            >
              2021/2022
            </p>
          </div>
          <div className="overflowHid">
            <p
              ref={(element) => {
                blocks.current[6] = element;
              }}
            >
              EBA
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
