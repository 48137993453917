import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { register } from "../../features/authSlice";
import "./Register.css";

const Register = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const registerErrors = useSelector((state) => state.auth.registerErrors);
  const [soloAccount, setSoloAccount] = useState(true);
  const [accountFirstName, setAccountFirstName] = useState();
  const [accountLastName, setAccountLastName] = useState();
  const [telephone, setTelephone] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const handleConfirm = () => {
    dispatch(
      register({
        soloAccount: soloAccount,
        accountFirstName: accountFirstName,
        accountLastName: accountLastName,
        telephone: telephone,
        email: email,
        password: password,
      })
    );
  };

  useEffect(() => {
    if (user !== null) {
      history.push("/customer-dashboard");
    }
  }, [history, user]);

  return (
    <div className="register">
      {registerErrors &&
        registerErrors.errors.map((element, i) => <p key={i}>{element.msg}</p>)}
      <form>
        <div className="register__labels">
          <label>
            Seul
            <input
              onClick={() => {
                setSoloAccount(true);
              }}
              name="soloAccount"
              type="radio"
              defaultChecked
            ></input>
          </label>
          <label>
            Famille
            <input
              onChange={(e) => {
                setSoloAccount(false);
              }}
              name="soloAccount"
              type="radio"
            ></input>
          </label>
        </div>
        <input
          onChange={(e) => {
            setAccountFirstName(e.target.value);
          }}
          id="accountFirstName"
          name="accountFirstName"
          type="text"
          placeholder="Prénom"
        ></input>
        <input
          onChange={(e) => {
            setAccountLastName(e.target.value);
          }}
          type="text"
          id="accountLastName"
          name="accountLastName"
          placeholder="Nom de famille"
        ></input>
        <input
          onChange={(e) => {
            setTelephone(e.target.value);
          }}
          type="number"
          id="telephone"
          name="telephone"
          placeholder="Téléphone"
        ></input>
        <input
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          id="email"
          name="email"
          type="text"
          placeholder="E-mail"
        ></input>
        <input
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type="text"
          id="password"
          name="password"
          placeholder="Mot de passe"
        ></input>
        <button type="button" onClick={handleConfirm}>
          INSCRIPTION
        </button>
      </form>
    </div>
  );
};

export default Register;
