import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Home.css";
import "../Activities/Activities.css";
import boxes from "../../images/boxes.webp";
import boxesL from "../../images/boxesL.webp";
import logo from "../../images/logo.png";
import dreamBoy from "../../images/dreamBoy.webp";
import Activities from "../Activities/Activities.js";
import Depth from "../Depth/Depth.js";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     const scrollTriggertUpdate = () => {
  //       setTimeout(() => {
  //         ScrollTrigger.refresh();
  //       });
  //     };
  //     window.addEventListener("load ", scrollTriggertUpdate());
  //     return () => {
  //       window.removeEventListener("load ", scrollTriggertUpdate());
  //     };
  //   });
  // });

  const les = ["l", "e", "s"];
  const écuries = ["é", "c", "u", "r", "i", "e", "s"];
  const de = ["d", "e"];
  const gammarth = ["g", "a", "m", "m", "a", "r", "t", "h"];

  const page = useRef(null);

  const logoRef = useRef(null);

  const h1Top = useRef(null);
  const h1Bot = useRef(null);
  const letterRef = useRef([]);

  const mainShape = useRef(null);
  const headerBoxes = useRef(null);
  const headerDreamboy = useRef(null);
  const dreamboyReversed = useRef(null);

  const circle = useRef(null);
  const circleLine = useRef(null);
  const pUn = useRef(null);
  const square = useRef(null);
  const squareLine = useRef(null);

  useEffect(() => {
    gsap.to(page.current, { duration: 0, css: { visibility: "visible" } });
    gsap.fromTo(
      logoRef.current,
      {
        opacity: 0,
      },
      {
        delay: 3.3,
        opacity: 1,
        duration: 1,
      }
    );
    gsap.set(dreamboyReversed.current, {
      yPercent: -100,
      xPercent: -30,
      rotation: -90,
      transformOrigin: "100% 0",
    });
    gsap
      .timeline()
      .fromTo(
        mainShape.current,
        { autoAlpha: 0 },
        {
          duration: 4,
          autoAlpha: 1,
        },
        "+=0.3"
      )
      .from(
        letterRef.current,
        {
          duration: 2,
          yPercent: 200,
          ease: "power4.out",
          skewY: 10,
          rotation: 0.01,
          stagger: {
            amount: 1,
          },
        },
        "-=3.5"
      )
      .fromTo(
        headerBoxes.current,
        { opacity: 0, y: 10 },
        {
          duration: 1.1,
          opacity: 1,
          y: 0,
        },
        "-=1.5"
      )
      .fromTo(
        headerDreamboy.current,
        { opacity: 0, y: 10 },
        {
          duration: 1.1,
          opacity: 1,
          y: 0,
        },
        "-=0.6"
      );

    gsap.fromTo(
      headerBoxes.current,
      { yPercent: 0 },
      {
        yPercent: -10,
        ease: "none",
        scrollTrigger: {
          trigger: headerDreamboy.current,
          scrub: 0.15,
          start: "0% 100%",
        },
      }
    );
    gsap.fromTo(
      headerDreamboy.current,
      { yPercent: 0 },
      {
        yPercent: 5,
        ease: "none",
        scrollTrigger: {
          trigger: headerDreamboy.current,
          scrub: 0.15,
          start: "0% 100%",
        },
      }
    );

    gsap.fromTo(
      mainShape.current,
      { yPercent: 0 },
      {
        yPercent: 100,
        rotation: 50,
        scale: 0,
        ease: "none",
        scrollTrigger: {
          trigger: mainShape.current,
          scrub: 1,
          start: "-10% 0%",
          end: "150% 0%",
        },
      }
    );

    gsap.fromTo(
      circle.current,
      { autoAlpha: 0 },
      {
        duration: 2.5,
        ease: "power2",
        autoAlpha: 1,
        rotation: 0.01,
        scrollTrigger: {
          trigger: pUn.current,
          start: "20% 78%",
        },
      }
    );
    gsap.to(circleLine.current, {
      duration: 1,
      scrollTrigger: {
        trigger: pUn.current,
        start: "20% 78%",
      },
      css: { width: "100%" },
    });
    gsap.from(pUn.current, {
      y: 30,
      duration: 1,
      delay: 0.5,
      ease: "power4.out",
      autoAlpha: 0,
      rotation: 0.01,
      scrollTrigger: {
        trigger: pUn.current,
        start: "20% 80%",
      },
    });
    gsap.from(square.current, {
      duration: 2.5,
      ease: "power2",
      autoAlpha: 0,
      rotation: 0.01,
      scrollTrigger: {
        trigger: pUn.current,
        start: "20% 80%",
      },
    });
    gsap.to(squareLine.current, {
      duration: 1,
      scrollTrigger: {
        trigger: pUn.current,
        start: "20% 80%",
      },
      css: { width: "100%" },
    });
  }, []);

  return (
    <div className="container">
      <div ref={page} className="home">
        <img
          className="logo"
          ref={logoRef}
          src={logo}
          width="332"
          height="360"
          alt="logo des écuries de gammarth"
        ></img>
        <div className="home__titleAndShape">
          <div ref={mainShape} className="home__mainShape tallArch"></div>
          <h1>
            <div ref={h1Top} className="home__h1__upper">
              <div>
                {les.map((el, i) => {
                  return (
                    <span key={i}>
                      <div
                        className="home__title__letters"
                        ref={(element) => {
                          letterRef.current[i] = element;
                        }}
                      >
                        {el}
                      </div>
                    </span>
                  );
                })}
              </div>
              <div className="home__h1__secondWord">
                {écuries.map((el, i) => {
                  return (
                    <span key={i}>
                      <div
                        className="home__title__letters"
                        ref={(element) => {
                          letterRef.current[i + 3] = element;
                        }}
                      >
                        {el}
                      </div>
                    </span>
                  );
                })}
              </div>
            </div>
            <div ref={h1Bot} className="home__h1__lower">
              {de.map((el, i) => {
                return (
                  <span key={i}>
                    <div
                      className="home__title__letters"
                      ref={(element) => {
                        letterRef.current[i + 10] = element;
                      }}
                    >
                      {el}
                    </div>
                  </span>
                );
              })}
              <div className="home__h1__secondWord">
                {gammarth.map((el, i) => {
                  return (
                    <span key={i}>
                      <div
                        className="home__title__letters"
                        ref={(element) => {
                          letterRef.current[i + 12] = element;
                        }}
                      >
                        {el}
                      </div>
                    </span>
                  );
                })}
              </div>
            </div>
          </h1>
        </div>
        <div className="home__headerOverlapAndServices">
          <div className="home__headerOverlap">
            <div ref={headerBoxes} className="home__headerOverlap__fullBoxes">
              <div className="home__headerOverlap__boxes">
                <img
                  width="176"
                  height="192"
                  srcSet={`${boxes} 600w, ${boxesL} 3500w`}
                  src={boxes}
                  alt=""
                ></img>
                <div className="home__headerOverlap__boxes__txt">
                  <p className="pDeco">Intérieur</p>
                  <p className="pDeco">n°01</p>
                </div>
              </div>
              <p className="home__headerOverlap__boxes__p pDeco">07/20</p>
            </div>
            <div ref={headerDreamboy} className="home__headerOverlap__dreamBoy">
              <p ref={dreamboyReversed} className="pDeco reversedText">
                DreamBoy
              </p>
              <img width="160" height="107" src={dreamBoy} alt=""></img>
            </div>
          </div>
          <div className="home__services">
            <div className="home__services__circleContainer">
              <div ref={circle} className="home__services__circle"></div>
              <div
                ref={circleLine}
                className="home__services__circle__line line"
              ></div>
            </div>
            <p ref={pUn} className="pText">
              “Nous proposons des cours en carrière et des balades en fôret ou à
              la plage, pour tout niveau et tout âge.”
            </p>
            <div className="home__services__squareContainer">
              <div ref={square} className="home__services__square"></div>
              <div
                ref={squareLine}
                className="home__services__square__line line"
              ></div>
            </div>
          </div>
        </div>
        <Activities />
        <Depth />
      </div>
    </div>
  );
};

export default Home;
