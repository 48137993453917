import React, { useRef, useEffect } from "react";
import "./Depth.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import arche from "../../images/arche.png";
import dreamSaut from "../../images/dreamSaut.webp";
import dreamSaut_L from "../../images/dreamSaut_L.webp";
import nizar from "../../images/nizar.webp";
import coursShet from "../../images/coursShet.webp";
import voltige from "../../images/voltige.webp";
import foggy from "../../images/foggy.webp";
import plageDuo from "../../images/plageDuo.webp";
import plage from "../../images/plage.webp";
import saut from "../../images/saut.webp";
import saut2 from "../../images/saut2.webp";
import foret2 from "../../images/foret2.webp";
import foret from "../../images/foret.webp";
import dreamBoy2 from "../../images/dreamBoy2.webp";
import shetForet from "../../images/shetForet.webp";
import fb from "../../images/fb.png";
import ig from "../../images/ig.png";

gsap.registerPlugin(ScrollTrigger);

const Depth = () => {
  const open = (url) => {
    let win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  };

  const depthContainer = useRef(null);
  const depthArchSVG = useRef(null);
  const depthCoursH2 = useRef(null);
  const depthFirstImgOne = useRef(null);
  const depthFirstImgTwo = useRef(null);
  const depthFirstImgThree = useRef(null);
  const depthFirstImgFour = useRef(null);
  const depthFirstImgFive = useRef(null);
  const depthFirstP = useRef(null);
  const depthBiArch = useRef(null);
  const depthBiArchLeft = useRef(null);
  const depthBiArchLeftSVG = useRef(null);
  const depthBiArchLeftH2 = useRef(null);
  const depthBiArchRight = useRef(null);
  const depthBiArchRightH2 = useRef(null);
  const depthBiArchRightSVG = useRef(null);

  const depthPlageImgOne = useRef(null);
  const depthPlageImgTwo = useRef(null);
  const depthPlageImgThree = useRef(null);
  const depthPlageImgFour = useRef(null);
  const depthPlageH2 = useRef(null);
  const depthPlageSVG = useRef(null);
  const depthPlageP = useRef(null);

  const depthForetP = useRef(null);
  const depthForetImgFirst = useRef(null);
  const depthForetImgScnd = useRef(null);
  const depthForetImgThree = useRef(null);
  const depthForetImgFour = useRef(null);
  const depthForetSVG = useRef(null);

  const depthContactez = useRef(null);
  const depthNous = useRef(null);
  const depthFooterLeftArch = useRef(null);
  const depthFooterLeftArchSVG = useRef(null);
  const depthFooterLeftArchBtn = useRef(null);
  const depthFooterRightArch = useRef(null);
  const depthFooterRightArchSVG = useRef(null);
  const depthFooterRightArchBtn = useRef(null);
  const depthMadeBy = useRef(null);

  useEffect(() => {
    gsap.set(depthArchSVG.current, { yPercent: -50, xPercent: -50 });
    gsap.set(depthCoursH2.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.1,
    });
    gsap.set(depthFirstImgOne.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.05,
    });
    gsap.set(depthFirstImgTwo.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.05,
    });
    gsap.set(depthFirstImgThree.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.05,
    });
    gsap.set(depthFirstImgFour.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.05,
    });
    gsap.set(depthFirstImgFive.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.05,
    });
    gsap.set(depthFirstP.current, { yPercent: -50, xPercent: -50 });
    gsap.set(depthBiArch.current, { yPercent: -50, xPercent: -50 });
    gsap.set(depthBiArchLeftH2.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.01,
    });
    gsap.set(depthBiArchLeftSVG.current, { yPercent: -50, xPercent: 50 });
    gsap.set(depthBiArchRightH2.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.01,
    });
    gsap.set(depthBiArchRightSVG.current, { yPercent: -50, xPercent: 50 });
    gsap.set(depthPlageH2.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.0001,
    });
    gsap.set(depthPlageSVG.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.01,
    });
    gsap.set(depthPlageP.current, { yPercent: -50, xPercent: -50 });
    gsap.set(depthPlageImgOne.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.05,
    });
    gsap.set(depthPlageImgTwo.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.05,
    });
    gsap.set(depthPlageImgThree.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.05,
    });
    gsap.set(depthPlageImgFour.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.05,
    });
    gsap.set(depthForetP.current, { yPercent: -50, xPercent: -50 });
    gsap.set(depthForetImgFirst.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.05,
    });
    gsap.set(depthForetImgScnd.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.05,
    });
    gsap.set(depthForetImgThree.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.05,
    });
    gsap.set(depthForetImgFour.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.05,
    });
    gsap.set(depthForetSVG.current, {
      yPercent: -50,
      xPercent: -50,
      scale: 0.01,
    });
    gsap.set(depthFooterLeftArch.current, { yPercent: -50, xPercent: -50 });
    gsap.set(depthFooterLeftArchSVG.current, {
      yPercent: -50,
      xPercent: -50,
    });
    gsap.set(depthFooterLeftArchBtn.current, {
      yPercent: -50,
      xPercent: -50,
    });
    gsap.set(depthFooterRightArch.current, { yPercent: -50, xPercent: -50 });
    gsap.set(depthFooterRightArchSVG.current, {
      yPercent: -50,
      xPercent: -50,
    });
    gsap.set(depthFooterRightArchBtn.current, {
      yPercent: -50,
      xPercent: -50,
    });
    gsap.set(depthMadeBy.current, { xPercent: -50 });

    ScrollTrigger.matchMedia({
      "(max-width: 1024px)": function () {
        gsap.set(depthContactez.current, {
          yPercent: -180,
          xPercent: -50,
          rotation: -90,
        });
        gsap.set(depthNous.current, {
          yPercent: 280,
          rotation: -90,
          transformOrigin: "72% 0",
        });
        const timeline = gsap.timeline(
          {
            scrollTrigger: {
              trigger: depthContainer.current,
              scrub: 1,
              pin: depthContainer.current,
              pinSpacing: true,
              start: "center center",
              anticipatePin: true,
              end: "+=5000px",
              invalidateOnRefresh: true,
            },
          },
          { defaults: { ease: "none" } }
        );

        timeline
          .to(depthArchSVG.current, {
            ease: "none",
            scale: 12,
            duration: 600,
          })
          .to(
            depthCoursH2.current,
            {
              ease: "none",
              scale: 5,
              xPercent: -74.5,
              duration: 600,
            },
            "-=600"
          )
          .to(
            depthArchSVG.current,
            {
              backgroundColor: "rgb(255, 245, 220)",
              duration: 180,
            },
            "-=440"
          )
          .to(depthArchSVG.current, {
            autoAlpha: 0,
            duration: 0,
          })
          .to(depthCoursH2.current, {
            autoAlpha: 0,
            duration: 0,
          })
          .to(
            depthFirstImgOne.current,
            { ease: "power3.in", autoAlpha: 1, duration: 360 },
            "-=600"
          )
          .to(
            depthFirstImgOne.current,
            {
              ease: "power3.in",
              scale: 1.8,
              xPercent: -220,
              yPercent: 110,
              duration: 600,
            },
            "-=600"
          )
          .to(depthFirstImgOne.current, { autoAlpha: 0, duration: 0 })
          .to(depthFirstP.current, { autoAlpha: 1, duration: 100 }, "-=440")
          .to(
            depthFirstImgTwo.current,
            { ease: "power3.in", autoAlpha: 1, duration: 410 },
            "-=520"
          )
          .to(
            depthFirstImgTwo.current,
            {
              ease: "power3.in",
              scale: 1.8,
              xPercent: 150,
              yPercent: -190,
              duration: 600,
            },
            "-=520"
          )
          .to(depthFirstImgTwo.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthFirstImgThree.current,
            { ease: "power3.in", autoAlpha: 1, duration: 440 },
            "-=520"
          )
          .to(
            depthFirstP.current,
            {
              autoAlpha: 0,
              duration: 100,
            },
            "+=50"
          )
          .to(
            depthFirstImgThree.current,
            {
              ease: "power3.in",
              scale: 1.8,
              xPercent: -230,
              yPercent: -250,
              duration: 600,
            },
            "-=670"
          )
          .to(depthFirstImgThree.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthFirstImgFour.current,
            { ease: "power3.in", autoAlpha: 1, duration: 440 },
            "-=550"
          )
          .to(
            depthFirstImgFour.current,
            {
              ease: "power3.in",
              scale: 1.8,
              xPercent: 150,
              yPercent: 130,
              duration: 600,
            },
            "-=550"
          )
          .to(depthFirstImgFour.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthFirstImgFive.current,
            { ease: "power3.in", autoAlpha: 1, duration: 440 },
            "-=510"
          )
          .to(
            depthFirstImgFive.current,
            {
              ease: "power3.in",
              scale: 1.8,
              xPercent: 130,
              yPercent: -230,
              duration: 600,
            },
            "-=510"
          )
          .to(depthFirstImgFive.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthBiArch.current,
            { ease: "power3.in", scale: 12, duration: 700 },
            "-=600"
          )
          .to(
            depthBiArchLeftH2.current,
            { ease: "power3.in", scale: 0.0135, duration: 600 },
            "-=630"
          )
          .to(
            depthBiArchRightH2.current,
            { ease: "power3.in", scale: 0.0135, duration: 600 },
            "-=630"
          )
          .to(
            depthBiArch.current,
            { ease: "power3.in", autoAlpha: 1, duration: 450 },
            "-=630"
          )
          .to(
            depthBiArch.current,
            { ease: "power1", xPercent: 310, duration: 200 },
            "-=150"
          )
          .to(
            depthBiArchLeft.current,
            { ease: "power3.in", scale: 50, yPercent: -1500, duration: 50 },
            "-=100"
          )
          .to(
            depthBiArchRight.current,
            { ease: "power3.in", xPercent: 2500, duration: 50 },
            "-=100"
          )
          .to(
            depthBiArchLeftSVG.current,
            {
              backgroundColor: "#e5f1ff",
              duration: 30,
            },
            "-=50"
          )
          .to(
            depthPlageImgOne.current,
            { ease: "power3.in", autoAlpha: 1, duration: 130 },
            "-=110"
          )
          .to(
            depthPlageImgOne.current,
            {
              ease: "power3.in",
              scale: 1.8,
              xPercent: 100,
              yPercent: -230,
              duration: 600,
            },
            "-=410"
          )
          .to(depthPlageImgOne.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthPlageP.current,
            {
              autoAlpha: 1,
              duration: 100,
            },
            "-=210"
          )
          .to(
            depthPlageImgTwo.current,
            { ease: "power3.in", autoAlpha: 1, duration: 270 },
            "-=310"
          )
          .to(
            depthPlageImgTwo.current,
            {
              ease: "power3.in",
              scale: 1.8,
              xPercent: -280,
              yPercent: 170,
              duration: 600,
            },
            "-=490"
          )
          .to(depthPlageImgTwo.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthPlageImgThree.current,
            { ease: "power3.in", autoAlpha: 1, duration: 340 },
            "-=400"
          )
          .to(
            depthPlageImgThree.current,
            {
              ease: "power3.in",
              scale: 1.8,
              xPercent: 120,
              yPercent: 120,
              duration: 600,
            },
            "-=500"
          )
          .to(depthPlageImgThree.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthPlageP.current,
            {
              autoAlpha: 0,
              duration: 100,
            },
            "-=150"
          )
          .to(
            depthPlageImgFour.current,
            { ease: "power3.in", autoAlpha: 1, duration: 200 },
            "-=330"
          )
          .to(
            depthPlageImgFour.current,
            {
              ease: "power3.in",
              scale: 1.8,
              xPercent: -200,
              yPercent: -230,
              duration: 600,
            },
            "-=430"
          )
          .to(depthPlageImgFour.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthPlageSVG.current,
            { ease: "none", autoAlpha: 1, duration: 450 },
            "-=490"
          )
          .to(
            depthPlageH2.current,
            { ease: "none", autoAlpha: 1, duration: 450 },
            "-=460"
          )
          .to(
            depthPlageSVG.current,
            { ease: "power3.in", scale: 0.4, duration: 600 },
            "-=600"
          )
          .to(
            depthPlageH2.current,
            { ease: "power3.in", scale: 0.3, duration: 600 },
            "-=600"
          )
          .to(depthPlageH2.current, {
            ease: "power3.in",
            scale: 41,
            yPercent: 150,
            duration: 50,
          })
          .to(
            depthPlageSVG.current,
            { ease: "power3.in", scale: 4, duration: 50 },
            "-=54"
          )
          .to(depthPlageSVG.current, {
            backgroundColor: "#e5ede3",
            duration: 30,
          })
          .to(depthPlageH2.current, {
            ease: "power3.in",
            autoAlpha: 0,
            duration: 0,
          })
          .to(
            depthForetImgFirst.current,
            { ease: "power2.in", autoAlpha: 1, duration: 400 },
            "-=140"
          )
          .to(
            depthForetImgFirst.current,
            {
              ease: "power3.in",
              scale: 1.7,
              xPercent: 95,
              yPercent: 100,
              duration: 600,
            },
            "-=420"
          )
          .to(depthForetImgFirst.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthForetP.current,
            {
              autoAlpha: 1,
              duration: 100,
            },
            "-=300"
          )
          .to(
            depthForetImgScnd.current,
            { ease: "power2.in", autoAlpha: 1, duration: 300 },
            "-=380"
          )
          .to(
            depthForetImgScnd.current,
            {
              ease: "power3.in",
              scale: 1.7,
              xPercent: -180,
              yPercent: -220,
              duration: 600,
            },
            "-=460"
          )
          .to(depthForetImgScnd.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthForetP.current,
            {
              autoAlpha: 0,
              duration: 100,
            },
            "-=150"
          )
          .to(
            depthForetImgThree.current,
            { ease: "power2.in", autoAlpha: 1, duration: 300 },
            "-=440"
          )
          .to(
            depthForetImgThree.current,
            {
              ease: "power3.in",
              scale: 1.8,
              xPercent: -230,
              yPercent: 230,
              duration: 600,
            },
            "-=520"
          )
          .to(depthForetImgThree.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthForetSVG.current,
            { ease: "power2.in", autoAlpha: 1, duration: 300 },
            "-=540"
          )
          .to(
            depthForetSVG.current,
            {
              ease: "power3.in",
              scale: 0.4,
              duration: 600,
            },
            "-=720"
          )
          .to(depthForetSVG.current, {
            ease: "none",
            scale: 4,
            duration: 90,
          })
          .to(
            depthForetImgFour.current,
            { ease: "power2.in", autoAlpha: 1, duration: 300 },
            "-=440"
          )
          .to(
            depthForetImgFour.current,
            {
              ease: "power3.in",
              scale: 1.8,
              xPercent: 200,
              yPercent: -250,
              duration: 600,
            },
            "-=620"
          )
          .to(depthForetImgFour.current, { autoAlpha: 0, duration: 0 })
          .to(depthPlageSVG.current, {
            backgroundColor: "rgb(255,245,220)",
            duration: 0,
          })
          .to(depthForetSVG.current, { scale: 0.35, duration: 250 })
          .to(depthForetSVG.current, {
            autoAlpha: 0,
            duration: 0,
          })
          .to(depthContactez.current, { autoAlpha: 1, duration: 0 })
          .to(depthMadeBy.current, { autoAlpha: 1, duration: 0 })
          .to(depthFooterLeftArch.current, {
            autoAlpha: 1,
            duration: 0,
          })
          .to(depthFooterRightArch.current, {
            autoAlpha: 1,
            duration: 0,
          })
          .fromTo(
            depthFooterLeftArch.current,
            { left: "50%", right: "50%" },
            {
              left: "30%",
              right: "70%",
              scale: 0.7,
              duration: 200,
            }
          )
          .fromTo(
            depthFooterRightArch.current,
            { left: "50%", right: "50%" },
            {
              right: "30%",
              left: "70%",
              scale: 0.7,
              duration: 200,
            },
            "-=200"
          )
          .fromTo(
            depthContactez.current,
            { yPercent: -1000 },
            { yPercent: -180, duration: 400 },
            "-=300"
          )
          .to(depthNous.current, { autoAlpha: 1, duration: 0 }, "-=400")
          .fromTo(
            depthNous.current,
            { yPercent: 1000 },
            { yPercent: 67, duration: 400 },
            "-=400"
          )
          .to(
            depthFooterRightArchBtn.current,
            {
              autoAlpha: 1,
              duration: 50,
            },
            "-=100"
          )
          .to(
            depthFooterLeftArchBtn.current,
            {
              autoAlpha: 1,
              duration: 50,
            },
            "-=100"
          );
      },
      "(min-width: 1025px)": function () {
        gsap.set(depthContactez.current, {
          yPercent: 0,
          xPercent: 0,
          rotation: 0,
        });
        gsap.set(depthNous.current, {
          yPercent: 0,
          rotation: 0,
          transformOrigin: "0 0",
        });
        const timeline = gsap.timeline(
          {
            scrollTrigger: {
              trigger: depthContainer.current,
              scrub: 1,
              pin: depthContainer.current,
              pinSpacing: true,
              start: "center center",
              anticipatePin: true,
              end: "+=5000px",
              invalidateOnRefresh: true,
            },
          },
          { defaults: { ease: "none" } }
        );

        timeline
          .to(depthArchSVG.current, {
            ease: "none",
            scale: 12,
            duration: 600,
          })
          .to(
            depthCoursH2.current,
            {
              ease: "none",
              scale: 5,
              xPercent: -74.5,
              duration: 600,
            },
            "-=600"
          )
          .to(
            depthArchSVG.current,
            {
              backgroundColor: "rgb(255, 245, 220)",
              duration: 180,
            },
            "-=440"
          )
          .to(depthArchSVG.current, {
            autoAlpha: 0,
            duration: 0,
          })
          .to(depthCoursH2.current, {
            autoAlpha: 0,
            duration: 0,
          })
          .to(
            depthFirstImgOne.current,
            { ease: "power3.in", autoAlpha: 1, duration: 360 },
            "-=600"
          )
          .to(
            depthFirstImgOne.current,
            {
              ease: "power3.in",
              scale: 1.3,
              xPercent: -200,
              yPercent: 120,
              duration: 600,
            },
            "-=600"
          )
          .to(depthFirstImgOne.current, { autoAlpha: 0, duration: 0 })
          .to(depthFirstP.current, { autoAlpha: 1, duration: 100 }, "-=440")
          .to(
            depthFirstImgTwo.current,
            { ease: "power3.in", autoAlpha: 1, duration: 410 },
            "-=520"
          )
          .to(
            depthFirstImgTwo.current,
            {
              ease: "power3.in",
              scale: 1.3,
              xPercent: 150,
              yPercent: -190,
              duration: 600,
            },
            "-=520"
          )
          .to(depthFirstImgTwo.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthFirstImgThree.current,
            { ease: "power3.in", autoAlpha: 1, duration: 440 },
            "-=520"
          )
          .to(
            depthFirstP.current,
            {
              autoAlpha: 0,
              duration: 100,
            },
            "+=50"
          )
          .to(
            depthFirstImgThree.current,
            {
              ease: "power3.in",
              scale: 1.3,
              xPercent: -200,
              yPercent: -290,
              duration: 600,
            },
            "-=670"
          )
          .to(depthFirstImgThree.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthFirstImgFour.current,
            { ease: "power3.in", autoAlpha: 1, duration: 440 },
            "-=550"
          )
          .to(
            depthFirstImgFour.current,
            {
              ease: "power3.in",
              scale: 1.3,
              xPercent: 150,
              yPercent: 130,
              duration: 600,
            },
            "-=550"
          )
          .to(depthFirstImgFour.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthFirstImgFive.current,
            { ease: "power3.in", autoAlpha: 1, duration: 440 },
            "-=510"
          )
          .to(
            depthFirstImgFive.current,
            {
              ease: "power3.in",
              scale: 1.5,
              xPercent: 130,
              yPercent: -230,
              duration: 600,
            },
            "-=510"
          )
          .to(depthFirstImgFive.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthBiArch.current,
            { ease: "power3.in", scale: 12, duration: 700 },
            "-=600"
          )
          .to(
            depthBiArchLeftH2.current,
            { ease: "power3.in", scale: 0.0135, duration: 600 },
            "-=630"
          )
          .to(
            depthBiArchRightH2.current,
            { ease: "power3.in", scale: 0.0135, duration: 600 },
            "-=630"
          )
          .to(
            depthBiArch.current,
            { ease: "power3.in", autoAlpha: 1, duration: 450 },
            "-=630"
          )
          .to(
            depthBiArch.current,
            { ease: "power1", xPercent: 310, duration: 200 },
            "-=150"
          )
          .to(
            depthBiArchLeft.current,
            { ease: "power3.in", scale: 50, yPercent: -1500, duration: 50 },
            "-=100"
          )
          .to(
            depthBiArchRight.current,
            { ease: "power3.in", xPercent: 2500, duration: 50 },
            "-=100"
          )
          .to(
            depthBiArchLeftSVG.current,
            {
              backgroundColor: "#e5f1ff",
              duration: 30,
            },
            "-=50"
          )
          .to(
            depthPlageImgOne.current,
            { ease: "power3.in", autoAlpha: 1, duration: 130 },
            "-=110"
          )
          .to(
            depthPlageImgOne.current,
            {
              ease: "power3.in",
              scale: 1.3,
              xPercent: 70,
              yPercent: -230,
              duration: 600,
            },
            "-=410"
          )
          .to(depthPlageImgOne.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthPlageP.current,
            {
              autoAlpha: 1,
              duration: 100,
            },
            "-=210"
          )
          .to(
            depthPlageImgTwo.current,
            { ease: "power3.in", autoAlpha: 1, duration: 270 },
            "-=310"
          )
          .to(
            depthPlageImgTwo.current,
            {
              ease: "power3.in",
              scale: 1.3,
              xPercent: -280,
              yPercent: 170,
              duration: 600,
            },
            "-=490"
          )
          .to(depthPlageImgTwo.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthPlageImgThree.current,
            { ease: "power3.in", autoAlpha: 1, duration: 340 },
            "-=400"
          )
          .to(
            depthPlageImgThree.current,
            {
              ease: "power3.in",
              scale: 1.3,
              xPercent: 120,
              yPercent: 120,
              duration: 600,
            },
            "-=500"
          )
          .to(depthPlageImgThree.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthPlageP.current,
            {
              autoAlpha: 0,
              duration: 100,
            },
            "-=150"
          )
          .to(
            depthPlageImgFour.current,
            { ease: "power3.in", autoAlpha: 1, duration: 200 },
            "-=330"
          )
          .to(
            depthPlageImgFour.current,
            {
              ease: "power3.in",
              scale: 1.3,
              xPercent: -200,
              yPercent: -200,
              duration: 600,
            },
            "-=430"
          )
          .to(depthPlageImgFour.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthPlageSVG.current,
            { ease: "none", autoAlpha: 1, duration: 450 },
            "-=490"
          )
          .to(
            depthPlageH2.current,
            { ease: "none", autoAlpha: 1, duration: 450 },
            "-=460"
          )
          .to(
            depthPlageSVG.current,
            { ease: "power3.in", scale: 0.4, duration: 600 },
            "-=600"
          )
          .to(
            depthPlageH2.current,
            { ease: "power3.in", scale: 0.3, duration: 600 },
            "-=600"
          )
          .to(depthPlageH2.current, {
            ease: "power3.in",
            scale: 41,
            yPercent: 150,
            duration: 50,
          })
          .to(
            depthPlageSVG.current,
            { ease: "power3.in", scale: 4, duration: 50 },
            "-=54"
          )
          .to(depthPlageSVG.current, {
            backgroundColor: "#e5ede3",
            duration: 30,
          })
          .to(depthPlageH2.current, {
            ease: "power3.in",
            autoAlpha: 0,
            duration: 0,
          })
          .to(
            depthForetImgFirst.current,
            { ease: "power2.in", autoAlpha: 1, duration: 400 },
            "-=140"
          )
          .to(
            depthForetImgFirst.current,
            {
              ease: "power3.in",
              scale: 1.2,
              xPercent: 95,
              yPercent: 100,
              duration: 600,
            },
            "-=420"
          )
          .to(depthForetImgFirst.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthForetP.current,
            {
              autoAlpha: 1,
              duration: 100,
            },
            "-=300"
          )
          .to(
            depthForetImgScnd.current,
            { ease: "power2.in", autoAlpha: 1, duration: 300 },
            "-=380"
          )
          .to(
            depthForetImgScnd.current,
            {
              ease: "power3.in",
              scale: 1.3,
              xPercent: -150,
              yPercent: -220,
              duration: 600,
            },
            "-=460"
          )
          .to(depthForetImgScnd.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthForetP.current,
            {
              autoAlpha: 0,
              duration: 100,
            },
            "-=150"
          )
          .to(
            depthForetImgThree.current,
            { ease: "power2.in", autoAlpha: 1, duration: 300 },
            "-=440"
          )
          .to(
            depthForetImgThree.current,
            {
              ease: "power3.in",
              scale: 1.3,
              xPercent: -230,
              yPercent: 230,
              duration: 600,
            },
            "-=520"
          )
          .to(depthForetImgThree.current, { autoAlpha: 0, duration: 0 })
          .to(
            depthForetSVG.current,
            { ease: "power2.in", autoAlpha: 1, duration: 300 },
            "-=540"
          )
          .to(
            depthForetSVG.current,
            {
              ease: "power3.in",
              scale: 0.4,
              duration: 600,
            },
            "-=720"
          )
          .to(depthForetSVG.current, {
            ease: "none",
            scale: 4,
            duration: 90,
          })
          .to(
            depthForetImgFour.current,
            { ease: "power2.in", autoAlpha: 1, duration: 300 },
            "-=440"
          )
          .to(
            depthForetImgFour.current,
            {
              ease: "power3.in",
              scale: 1.3,
              xPercent: 200,
              yPercent: -250,
              duration: 600,
            },
            "-=620"
          )
          .to(depthForetImgFour.current, { autoAlpha: 0, duration: 0 })
          .to(depthPlageSVG.current, {
            backgroundColor: "rgb(255,245,220)",
            duration: 0,
          })
          .to(depthForetSVG.current, { scale: 0.35, duration: 250 })
          .to(depthForetSVG.current, {
            autoAlpha: 0,
            duration: 0,
          })
          .to(depthContactez.current, { autoAlpha: 1, duration: 0 })
          .to(depthMadeBy.current, { autoAlpha: 1, duration: 0 })
          .to(depthFooterLeftArch.current, {
            autoAlpha: 1,
            duration: 0,
          })
          .to(depthFooterRightArch.current, {
            autoAlpha: 1,
            duration: 0,
          })
          .fromTo(
            depthFooterLeftArch.current,
            { left: "50%", right: "50%" },
            {
              left: "30%",
              right: "70%",
              scale: 0.7,
              duration: 200,
            }
          )
          .fromTo(
            depthFooterRightArch.current,
            { left: "50%", right: "50%" },
            {
              right: "30%",
              left: "70%",
              scale: 0.7,
              duration: 200,
            },
            "-=200"
          )
          .fromTo(
            depthContactez.current,
            { xPercent: -120 },
            { xPercent: 0, duration: 400 },
            "-=300"
          )
          .to(depthNous.current, { autoAlpha: 1, duration: 0 }, "-=400")
          .fromTo(
            depthNous.current,
            { xPercent: 120 },
            { xPercent: 0, duration: 400 },
            "-=400"
          )
          .to(
            depthFooterRightArchBtn.current,
            {
              autoAlpha: 1,
              duration: 50,
            },
            "-=100"
          )
          .to(
            depthFooterLeftArchBtn.current,
            {
              autoAlpha: 1,
              duration: 50,
            },
            "-=100"
          );
      },
    });
  }, []);

  return (
    <div ref={depthContainer} className="home__depth__first">
      <img
        width="2000"
        height="2256"
        src={arche}
        loading="lazy"
        ref={depthArchSVG}
        className="home__depthArch"
        alt=""
      ></img>
      <h2 className="home__depth__coursH2" ref={depthCoursH2}>
        COURS
      </h2>
      <img
        loading="lazy"
        width="60"
        height="89"
        ref={depthFirstImgOne}
        className="home__depth__first__img1"
        src={dreamSaut}
        srcSet={`${dreamSaut} 500w, ${dreamSaut_L} 1000w`}
        alt=""
      ></img>
      <img
        loading="lazy"
        width="25"
        height="38"
        ref={depthFirstImgTwo}
        className="home__depth__first__img2"
        src={voltige}
        alt=""
      ></img>
      <img
        loading="lazy"
        width="25"
        height="18"
        ref={depthFirstImgThree}
        className="home__depth__first__img3"
        src={saut2}
        alt=""
      ></img>
      <img
        loading="lazy"
        width="25"
        height="37"
        ref={depthFirstImgFour}
        className="home__depth__first__img4"
        src={coursShet}
        alt=""
      ></img>
      <img
        loading="lazy"
        width="25"
        height="26"
        ref={depthFirstImgFive}
        className="home__depth__first__img5"
        src={nizar}
        alt=""
      ></img>
      <p className="home__depth__p" ref={depthFirstP}>
        "Découvrez l'équitation ou améliorez votre niveau grace à nos cours
        assurés en français, anglais, arabe et italien."
      </p>
      <div ref={depthBiArch} className="home__depth__first__biArch">
        <div ref={depthBiArchLeft} className="home__depth__first__biArch__left">
          <h2 ref={depthBiArchLeftH2}>Plage</h2>
          <img
            width="2000"
            height="2256"
            src={arche}
            loading="lazy"
            ref={depthBiArchLeftSVG}
            alt=""
          ></img>
        </div>
        <div
          ref={depthBiArchRight}
          className="home__depth__first__biArch__right"
        >
          <h2 ref={depthBiArchRightH2}>Forêt</h2>
          <img
            width="2000"
            height="2256"
            src={arche}
            loading="lazy"
            ref={depthBiArchRightSVG}
            alt=""
          ></img>
        </div>
      </div>
      <p className="home__depth__p" ref={depthPlageP}>
        "Nous proposons des promenades sur la plage aux trois allures toute
        l'année et des baignades à cheval l'été."
      </p>
      <img
        loading="lazy"
        width="46"
        height="26"
        ref={depthPlageImgOne}
        className="home__depth__plage__img1"
        src={plage}
        alt=""
      ></img>
      <img
        loading="lazy"
        width="21"
        height="29"
        ref={depthPlageImgTwo}
        className="home__depth__plage__img2"
        src={saut}
        alt=""
      ></img>
      <img
        loading="lazy"
        width="29"
        height="22"
        ref={depthPlageImgThree}
        className="home__depth__plage__img3"
        src={foggy}
        alt=""
      ></img>
      <img
        loading="lazy"
        width="25"
        height="36"
        ref={depthPlageImgFour}
        className="home__depth__plage__img4"
        src={plageDuo}
        alt=""
      ></img>
      <img
        width="2000"
        height="2256"
        src={arche}
        loading="lazy"
        ref={depthPlageSVG}
        className="home__depth__plage__svg"
        alt=""
      ></img>
      <h2 className="home_depth__plage__h2" ref={depthPlageH2}>
        Forêt
      </h2>
      <p className="home__depth__p" ref={depthForetP}>
        "Également des sorties en forêt pour du cross, des galopades... Ou
        simplement un moment calme et reposant."
      </p>
      <img
        loading="lazy"
        width="46"
        height="56"
        className="home__depth__foret__img1"
        ref={depthForetImgFirst}
        src={foret2}
        alt=""
      ></img>
      <img
        loading="lazy"
        width="46"
        height="34"
        className="home__depth__foret__img2"
        ref={depthForetImgScnd}
        src={foret}
        alt=""
      ></img>
      <img
        loading="lazy"
        width="29"
        height="36"
        className="home__depth__foret__img3"
        ref={depthForetImgThree}
        src={dreamBoy2}
        alt=""
      ></img>
      <img
        loading="lazy"
        width="17"
        height="11"
        className="home__depth__foret__img4"
        ref={depthForetImgFour}
        src={shetForet}
        alt=""
      ></img>
      <img
        width="2000"
        height="2256"
        src={arche}
        loading="lazy"
        ref={depthForetSVG}
        className="home__depth__foret__svg"
        alt=""
      ></img>
      <h2 ref={depthContactez} className="depth__contactez">
        contactez-
      </h2>
      <h2 ref={depthNous} className="depth__nous">
        nous.
      </h2>
      <div ref={depthFooterLeftArch} className="home__depth__footerLinks">
        <img
          width="2000"
          height="2256"
          src={arche}
          loading="lazy"
          ref={depthFooterLeftArchSVG}
          alt=""
        ></img>
        <button
          onClick={() =>
            open("https://www.facebook.com/Les.Ecuries.de.Gammarth")
          }
          ref={depthFooterLeftArchBtn}
        >
          <img
            width="189"
            height="189"
            loading="lazy"
            className="home__depth__footerLinks__social fbLink"
            src={fb}
            alt="facebook link"
          ></img>
        </button>
      </div>
      <div ref={depthFooterRightArch} className="home__depth__footerLinks">
        <img
          width="2000"
          height="2256"
          src={arche}
          loading="lazy"
          ref={depthFooterRightArchSVG}
          alt=""
        ></img>
        <button
          onClick={() =>
            open(
              "https://instagram.com/les_ecuries_de_gammarth?igshid=a8loye5rapep"
            )
          }
          ref={depthFooterRightArchBtn}
        >
          <img
            width="226"
            height="226"
            loading="lazy"
            className="home__depth__footerLinks__social"
            src={ig}
            alt="instagram link"
          ></img>
        </button>
      </div>
      <p ref={depthMadeBy} className="depth__madeBy">
        2021 - 2022
      </p>
    </div>
  );
};

export default Depth;
