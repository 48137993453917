import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuthUser, noCurrentUser } from "./features/authSlice.js";
import "./App.css";
import Home from "./Components/pages/Home.js";
import Login from "./Components/auth/Login.js";
import Register from "./Components/auth/Register.js";
import Contact from "./Components/pages/Contact.js";
import AppNavBar from "./Components/AppNavBar.js";
import Rotate from "./Components/Rotate/Rotate.js";
// import CustomCursor from "./CustomCursor/CustomCursor.js";

const PrivateRoute = lazy(() => import("./Components/route/PrivateRoute.js"));
const CustomerDashboard = lazy(() =>
  import("./Components/pages/CustomerDashboard.js")
);
const AdminDashboard = lazy(() =>
  import("./Components/pages/AdminDashboard.js")
);
const TeacherDashboard = lazy(() =>
  import("./Components/pages/TeacherDashboard.js")
);
const ManageRiders = lazy(() => import("./Components/pages/ManageRiders.js"));
const ManageUsers = lazy(() => import("./Components/pages/ManageUsers.js"));
const TeacherManageRiders = lazy(() =>
  import("./Components/pages/TeacherManageRiders.js")
);
const AdminManageHorses = lazy(() =>
  import("./Components/pages/AdminManageHorses.js")
);
const Planning = lazy(() => import("./Components/pages/Planning.js"));

// import Spinner from "./Components/Spinner.js";

function App() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.isLoading);

  useEffect(() => {
    const getUser = () => dispatch(getAuthUser("x"));
    if (localStorage.getItem("token")) {
      getUser();
    } else {
      dispatch(noCurrentUser());
    }
  }, [dispatch]);

  if (loading) {
    return <div></div>;
  } else {
    return (
      <Router>
        <Suspense
          fallback={
            <div>
              <h1>LAZY LOADING</h1>
            </div>
          }
        >
          <AppNavBar />
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/">
              <Rotate />
              <Home />
            </Route>
            <Route exact path="/new-account">
              <Register />
            </Route>
            <PrivateRoute exact path="/planning" component={Planning} />
            <PrivateRoute
              exact
              path="/customer-dashboard"
              component={CustomerDashboard}
            />
            <PrivateRoute
              exact
              path="/admin-dashboard"
              component={AdminDashboard}
            />
            <PrivateRoute
              exact
              path="/admin-dashboard/manage-riders"
              component={ManageRiders}
            />
            <PrivateRoute
              exact
              path="/admin-dashboard/manage-users"
              component={ManageUsers}
            />
            <PrivateRoute
              exact
              path="/admin-dashboard/manage-horses"
              component={AdminManageHorses}
            />
            <PrivateRoute
              exact
              path="/teacher-dashboard"
              component={TeacherDashboard}
            />
            <PrivateRoute
              exact
              path="/teacher-dashboard/manage-riders"
              component={TeacherManageRiders}
            />
          </Switch>
        </Suspense>
      </Router>
    );
  }
}

export default App;
