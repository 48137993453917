import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./AppNavBar.css";
import { logout } from "../features/authSlice";
import fer from "../images/fer.png";

gsap.registerPlugin(ScrollTrigger);

const AppNavBar = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const authentified = useSelector((state) => state.auth);
  const [showMenu, setShowMenu] = useState(false);
  const [animateMenu, setAnimateMenu] = useState(gsap.to({}, { duration: 0 }));
  const location = useLocation();
  const container = useRef(null);
  const menuLeft = useRef(null);
  const leftLinks = useRef([]);
  const rightLinks = useRef([]);
  const menuRight = useRef(null);
  const menu = useRef(null);
  const menuLink = useRef(null);
  const imgLink = useRef(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logout());
    history.push("/");
    setShowMenu(false);
  };

  let open = (url) => {
    let win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  };

  let EspaceLink = () => {
    if (
      authentified.user.status === "OwnerAccount" ||
      authentified.user.status === "Pending" ||
      authentified.user.status === "RiderAccount"
    ) {
      return (
        <Link
          className="menuLink"
          onClick={() => setShowMenu(false)}
          to="/customer-dashboard"
        >
          Espace Client
        </Link>
      );
    } else if (authentified.user.status === "Admin") {
      return (
        <Link
          className="menuLink"
          onClick={() => setShowMenu(false)}
          to="/admin-dashboard"
        >
          Espace Admin
        </Link>
      );
    } else if (authentified.user.status === "Teacher") {
      return (
        <Link
          className="menuLink"
          onClick={() => setShowMenu(false)}
          to="/teacher-dashboard"
        >
          Espace Moniteur
        </Link>
      );
    } else {
      return "";
    }
  };

  useEffect(() => {
    animateMenu.reversed(!showMenu);
  }, [showMenu, animateMenu]);

  useEffect(() => {
    if (location.pathname === "/") {
      gsap.fromTo(
        menuLink.current,
        {
          opacity: 0,
        },
        {
          delay: 3.3,
          opacity: 1,
          duration: 1,
        }
      );
    }
  }, [location.pathname]);

  useEffect(() => {
    gsap.to(container.current, { duration: 0, css: { visibility: "visible" } });

    setAnimateMenu(
      gsap
        .timeline()
        .to(menuLeft.current, {
          duration: 1,
          ease: "power2.inOut",
          left: 0,
        })
        .to(
          menuRight.current,
          {
            duration: 1,
            ease: "power2.inOut",
            right: 0,
          },
          "-=1"
        )
        .to(
          imgLink.current,
          {
            duration: 1,
            rotate: 180,
          },
          "-=1"
        )
        .to(
          menuLeft.current,
          { boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)", duration: 0.1 },
          "-=0.1"
        )
        .to(
          menuRight.current,
          { boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)", duration: 0.1 },
          "-=0.1"
        )
        .from(leftLinks.current, {
          duration: 0.8,
          ease: "power4.out",
          autoAlpha: 0,
          stagger: 0.1,
          y: 100,
        })
        .from(
          rightLinks.current,
          {
            duration: 0.8,
            ease: "power4.out",
            autoAlpha: 0,
            stagger: 0.1,
            y: 100,
          },
          "-=1"
        )
        .reverse()
    );
  }, []);

  return (
    <div ref={container} className="appNavBar">
      <div
        ref={menuLink}
        onClick={() => setShowMenu(!showMenu)}
        className="menuOpen"
      >
        <img
          width="166"
          height="166"
          src={fer}
          ref={imgLink}
          className="imgLink"
          alt="icone du menu"
        ></img>
      </div>
      <div ref={menu} className="menu">
        <div ref={menuLeft} className="menuLeft">
          <Link
            ref={(element) => {
              leftLinks.current[0] = element;
            }}
            className="menuLink"
            onClick={() => {
              setShowMenu(false);
            }}
            to="/"
          >
            Accueil
          </Link>
          {!isAuth ? (
            <Link
              ref={(element) => {
                leftLinks.current[1] = element;
              }}
              className="menuLink"
              onClick={() => setShowMenu(false)}
              to="/login"
            >
              Connexion
            </Link>
          ) : (
            <div>
              <div
                ref={(element) => {
                  leftLinks.current[1] = element;
                }}
              >
                <EspaceLink></EspaceLink>
              </div>
              <p
                className="menuLink"
                onClick={handleLogout}
                ref={(element) => {
                  leftLinks.current[4] = element;
                }}
              >
                Déconnexion
              </p>
            </div>
          )}
          <Link
            ref={(element) => {
              leftLinks.current[2] = element;
            }}
            className="menuLink leftContact"
            onClick={() => setShowMenu(false)}
            to="/contact"
          >
            Contact
          </Link>
        </div>
        <div ref={menuRight} className="menuRight">
          <Link
            ref={(element) => {
              rightLinks.current[0] = element;
            }}
            className="menuLink contactLink"
            onClick={() => setShowMenu(false)}
            to="/contact"
          >
            Contact
          </Link>
          <button
            className="social"
            ref={(element) => {
              rightLinks.current[1] = element;
            }}
            onClick={() =>
              open("https://www.facebook.com/Les.Ecuries.de.Gammarth")
            }
          >
            Facebook
          </button>
          <button
            className="social"
            ref={(element) => {
              rightLinks.current[2] = element;
            }}
            onClick={() =>
              open(
                "https://instagram.com/les_ecuries_de_gammarth?igshid=a8loye5rapep"
              )
            }
          >
            Instagram
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppNavBar;
