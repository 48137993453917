import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from "../../features/authSlice";
import "./Login.css";

const Login = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const loginErrors = useSelector((state) => state.auth.loginErrors);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const handleConfirm = () => {
    dispatch(login({ email: email, password: password }));
  };

  useEffect(() => {
    if (user !== null) {
      history.push("/customer-dashboard");
    }
  }, [history, user]);

  return (
    <div className="login">
      {loginErrors !== null && loginErrors.errors
        ? loginErrors.errors.map((element, i) => <p key={i}>{element.msg}</p>)
        : ""}
      {loginErrors !== null && loginErrors.msg ? <p>{loginErrors.msg}</p> : ""}
      <form>
        <input
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          id="email"
          name="email"
          type="text"
          placeholder="E-mail"
        ></input>
        <input
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type="password"
          id="password"
          name="password"
          placeholder="Password"
        ></input>
        <button type="button" onClick={handleConfirm}>
          LOGIN
        </button>
      </form>
    </div>
  );
};

export default Login;
